import React, { useState, useEffect } from "react";
import { GetAssessmentResults } from "../../infra/requests/CulturalAssessmentsRequests";
import { Row, Col } from "antd";
import { SpinLoading } from "../../styles/BasicStyles";
import LegalEntities from "../../infra/utils/LegalEntities";
import Roles from "../../infra/utils/Roles";

const GroupAnswers = (data) => {
  const entities = [];
  const roles = [];

  data.forEach((form) => {
    const entityIndex = entities.findIndex((e) => e._id == form.entity);
    if (entityIndex > -1) entities[entityIndex].total++;
    else {
      const entity = LegalEntities.find((l) => l._id == form.entity);
      entities.push({
        _id: form.entity,
        name: entity?.name || "Unknown",
        total: 1,
      });
    }

    const roleIndex = roles.findIndex((r) => r._id == form.role);
    if (roleIndex > -1) roles[roleIndex].total++;
    else {
      const role = Roles.find((r) => r._id == form.role);
      roles.push({
        _id: form.role,
        name: role?.name || "Unknown",
        total: 1,
      });
    }
  });

  return { entities, roles };
};

const CalculateClientAssessments = ({ assessment, client }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const result = await GetAssessmentResults(assessment, client);
      setData(result.data || []);
      setLoading(false);
    }
    init();
  }, [assessment, client]);

  if (loading) return <SpinLoading />;

  const { entities, roles } = GroupAnswers(data);

  const renderList = (list) =>
    list.map((r) => <div key={r._id}>{`${r.name}: ${r.total}`}</div>);

  return (
    <Row gutter={[24, 6]}>
      <Col xs={24}>
        <b>Total Assessments made:</b> {data.length}
      </Col>
      {entities.length > 0 && (
        <Col xs={12}>
          <b>By Workplace:</b> {renderList(entities)}
        </Col>
      )}
      {roles.length > 0 && (
        <Col xs={12}>
          <b>By Role:</b> {renderList(roles)}
        </Col>
      )}
    </Row>
  );
};

export default CalculateClientAssessments;
