import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal, notification } from "antd";
import {
  InputDiv,
  InputLabelDiv,
  ImageInputSection,
  ImageIcon,
  ImageMessage,
  DynamicImage,
} from "./InputStyles";

class ImageInput extends Component {
  state = {
    openModal: false,
    uploaded: undefined,
  };

  onCancel = () => {
    this.setState({ openModal: false });
  };

  buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob,
  });

  handleImageDrop = (accepted, rejected) => {
    const { input, noCrop } = this.props;

    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: "Invalid file",
        description: "The file needs to me smaller than 6MB",
      });
    }

    const uploaded = this.buildImageObject(accepted[0]);

    if (noCrop) {
      input.onChange(uploaded);
    } else {
      this.setState({ openModal: true, uploaded });
    }
  };

  onCropComplete = () => {
    const { input } = this.props;
    this.refs.cropper.getCroppedCanvas().toBlob((blob) => {
      const image = this.buildImageObject(blob);
      input.onChange(image);
      this.setState({ openModal: false, uploaded: undefined });
    });
  };

  BuildFileUrl = (id) => `${process.env.REACT_APP_IMAGES_URL}${id}`;

  renderSelectedMedia = ({ getRootProps, getInputProps }) => {
    const { input, meta } = this.props;
    const { invalid, submitFailed } = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    if (input?.value?.preview) {
      return (
        <ImageInputSection auto error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <DynamicImage src={input.value.preview} alt="uploaded" />
        </ImageInputSection>
      );
    }

    if (input?.value?.url) {
      return (
        <ImageInputSection auto error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <DynamicImage
            src={this.BuildFileUrl(input.value.file)}
            alt="uploaded"
          />
        </ImageInputSection>
      );
    }

    return (
      <ImageInputSection error={showError} {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageIcon error={showError} type="file-image" />
        <ImageMessage error={showError}>
          Clique para adicionar ou arraste uma imagem
        </ImageMessage>
      </ImageInputSection>
    );
  };

  render() {
    const { label, ratio, disabled } = this.props;
    const { openModal, uploaded } = this.state;
    return (
      <InputDiv>
        {label && <InputLabelDiv>{label}</InputLabelDiv>}
        <Dropzone
          multiple={false}
          onDrop={this.handleImageDrop}
          accept="image/jpeg, image/png"
          maxSize={60000000}
          disabled={disabled}
        >
          {this.renderSelectedMedia}
        </Dropzone>
        <Modal
          maskClosable={false}
          closable={false}
          title="Crop the image as you want to show"
          visible={openModal}
          onOk={this.onCropComplete}
          onCancel={this.onCancel}
          bodyStyle={{ padding: 0 }}
          width="600px"
        >
          <Cropper
            ref="cropper"
            viewMode={2}
            autoCropArea={1}
            aspectRatio={1 / ratio}
            style={{ height: 400, width: "100%" }}
            guides={true}
            src={uploaded ? uploaded.preview : ""}
          />
        </Modal>
      </InputDiv>
    );
  }
}

ImageInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default ImageInput;
