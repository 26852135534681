import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import { BaseStyledButton, SurveyContainer, SurveyInfoImage, SurveyScroll } from './SurveysStyles'
import { BodySmallSurvey, BodySurvey, ButtonTitle, SubTitleSurvey, TitleSurveyH5 } from '../../styles/TextSizes'
import { getImage, GetValuePreview } from '../../infra/utils/CommonFunctions'
import Language from '../../components/generic/language/Language'
import { Margin } from '../../styles/BasicStyles'

const SurveyInfo = ({
  survey,
  onPressNext,
  activeLanguage,
  translate
}) => {

  return (
    <SurveyScroll>
      <SurveyContainer>
      <Language />
      <Margin $size={40} />
        <TitleSurveyH5 style={{marginBottom: 16}}>
         {translate('WELCOME')}
        </TitleSurveyH5>
        <SubTitleSurvey style={{marginBottom: 40}}>
          {translate("SURVEY_INFO_PAGE_TITLE", {
            name: survey?.client?.name,
          })}
        </SubTitleSurvey>
        <BodySurvey style={{marginBottom: 48, whiteSpace: 'pre-wrap'}}>
          {GetValuePreview(survey.intro, activeLanguage.code)}
        </BodySurvey>
        <br />
        {survey?.client?.logo && (
          <SurveyInfoImage src={getImage(survey.client.logo.file)} alt='image'/>
        )}
        <br />
        <BaseStyledButton
          category='primary'
          onClick={() => onPressNext('intro')}
        >
          <ButtonTitle>{translate('START_ASSESSMENT')}</ButtonTitle>
        </BaseStyledButton>
        <BodySmallSurvey style={{marginTop: 40}}>
          {translate('FOR_QUESTIONS_CONTACT')}
        </BodySmallSurvey>
      </SurveyContainer>
    </SurveyScroll>
  )
}

SurveyInfo.propTypes = {
  survey: PropTypes.object,
  onPressNext: PropTypes.func,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

SurveyInfo.defaultProps = {
  onPressNext: () => {},
}

export default withLocalize(SurveyInfo)
