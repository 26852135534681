import React, {  useState } from "react";
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import {
  LangHeader,
  LangContainer,
  LangText,
  ArrowImg,
  SelectLangContainer,
  SelectLang,
  SelectLangText,
} from "./LanguageStyles";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";
import Arrow from "../../../assets/icons/chevron-down.svg";

const Language = ({
  setActiveLanguage, 
  languages, 
  activeLanguage, 
  paddingRight,
  translate
}) => {
  const [showLanguages, setShowLanguages] = useState(false)

  const changeLanguage = (e, code) => {
    e.stopPropagation();
    e.preventDefault();

    setActiveLanguage(code);
    moment.locale(code);
    document.cookie = `LANG_COOKIE=${code}; path=/`;
    localStorage.setItem("LANG_COOKIE", code);
    setShowLanguages(false)
  };

  return (
    <LangHeader style={{ paddingRight: paddingRight ? paddingRight : 0 }}>
      {languages && languages.length > 0 && (
        <OutsideAlerter close={() => setShowLanguages(false)}>
          <LangContainer
            onClick={() => setShowLanguages(!showLanguages)}
          >
            <LangText>{activeLanguage ? translate(`LANGUAGE_${activeLanguage.code.toUpperCase()}`) : ""}</LangText>
            <ArrowImg showLanguages={showLanguages}>
              <img src={Arrow} />
            </ArrowImg>
            <SelectLangContainer showLanguages={showLanguages}>
              <SelectLang>
                {languages
                  .filter((language) => language.code !== activeLanguage.code)
                  .map((language, index) => (
                    <SelectLangText
                      key={index}
                      onClick={(e) => changeLanguage(e, language.code)}
                    >
                      {translate(`LANGUAGE_${language.code.toUpperCase()}`)}
                    </SelectLangText>
                  ))}
              </SelectLang>
            </SelectLangContainer>
          </LangContainer>
        </OutsideAlerter>
      )}
    </LangHeader>
  );
}

export default withLocalize(Language);
