import React, { useState } from "react";

import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

import {
  BaseStyledButton,
  SurveyBackContainer2,
  SurveyBox2,
  SurveyFooter,
  SurveyFooterRight,
  SurveyHeader2,
  SurveyHeaderRestart,
  SurveyScroll3,
} from "./SurveysStyles";
import {
  BodySmall2Survey,
  BodySmallSurvey,
  ButtonTitle,
  SubTitleSurvey,
  TextHeader2Survey,
  TitleSurveyH5,
} from "../../styles/TextSizes";
import { ReactComponent as ShapeBack } from "../../assets/icons/chevron-left-blue.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { ReactComponent as Shape } from "../../assets/icons/check.svg";
import { Col, Row } from "antd";
import { Progress } from "antd";
import { SuccessButtonColor } from "../../styles/Colours";
import { Field, reset } from "redux-form";
import { useDispatch } from "react-redux";
import SurveySelectInput from "../../components/generic/inputs/SurveySelectInput";
import LegalEntities from "../../infra/utils/LegalEntities";
import Roles from "../../infra/utils/Roles";
import { SubmitSurvey } from "../../infra/requests/SurveysRequests";
import { RowContainer } from "../../styles/BasicStyles";
import Language from "../../components/generic/language/Language";
import { Languages } from "../../infra/services/translations/AvailableTranslations";

const SurveySubmit = ({
  survey,
  onPressNext,
  activeLanguage,
  translate,
  handleSubmit,
  assessment,
  client,
}) => {
  const [loading, setLoading] = useState(false);
  const number = survey.chapters.length + 1;
  const dispatch = useDispatch();

  const onPressStart = () => {
    dispatch(reset("survey_form"));
    onPressNext("intro");
  };

  const onSubmit = async (values) => {
    // values.chapters.map(c => {
    //   if (c.present.collaborate) c.present.collaborate = 0
    //   if (c.present.create) c.present.create = 0
    //   if (c.present.compete) c.present.compete = 0
    //   if (c.present.control) c.present.control = 0
    //   if (c.future.collaborate) c.future.collaborate = 0
    //   if (c.future.create) c.future.create = 0
    //   if (c.future.compete) c.future.compete = 0
    //   if (c.future.control) c.future.control = 0
    // })
    // console.log(values)
    try {
      setLoading(true);
      const result = await SubmitSurvey({ ...values, assessment, client });
      setLoading(false);
      if (result.success) onPressNext("success");
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <SurveyBackContainer2>
      <SurveyHeader2>
        <Row style={{ width: "100%" }}>
          <Col lg={18} xs={24}>
            <TextHeader2Survey style={{ marginBottom: 8 }}>
              {translate("CATEGORY_NUMBER", { number: number })}
            </TextHeader2Survey>
            <TitleSurveyH5 color="#ffffff" style={{ marginBottom: 16 }}>
              {translate("DEMOGRAPHIC_DATA")}
            </TitleSurveyH5>
            <BodySmallSurvey color="#ffffff">
              {translate("DEMOGRAPHIC_DATA_DESCRIPTION")}
            </BodySmallSurvey>
          </Col>
          <Col lg={6} xs={24}>
            <RowContainer>
              <Language paddingRight={30} />
              <SurveyHeaderRestart onClick={onPressStart}>
                <Refresh />
              </SurveyHeaderRestart>
            </RowContainer>
          </Col>
        </Row>
      </SurveyHeader2>
      <SurveyScroll3>
        <SurveyBox2>
          <Row>
            <Col lg={12} xs={24}>
              <SubTitleSurvey style={{ marginBottom: 40 }}>
                {translate("PLEASE_CHOOSE_YOUR_LEGAL_ENTITY_AND_ROLE")}
              </SubTitleSurvey>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} xs={24}>
              <Field
                component={SurveySelectInput}
                name="entity"
                subLabel={translate("WORKPLACE")}
                label={translate("WORKPLACE_DESCRIPTION")}
                placeholder={translate("SELECT_WORKPLACE")}
                dataKey="_id"
                dataLabel="name"
                data={LegalEntities}
                translatable
              />
              <Field
                component={SurveySelectInput}
                name="role"
                subLabel={translate("YOUR_ROLE")}
                label={translate("ROLE_QUESTION")}
                placeholder={translate("SELECT_ROLE")}
                dataKey="_id"
                dataLabel="name"
                data={Roles}
                translatable
              />
              <BaseStyledButton
                style={{ display: "flex", marginTop: 24 }}
                category="success"
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
              >
                <ButtonTitle>{translate("SUBMIT")} </ButtonTitle>
                <Shape style={{ marginLeft: 8 }} />
              </BaseStyledButton>
            </Col>
          </Row>
        </SurveyBox2>
      </SurveyScroll3>
      <SurveyFooter>
        <Progress
          type="circle"
          percent={100}
          width={56}
          trailColor="#c0c0c0"
          strokeColor={SuccessButtonColor}
          success={{ percent: 100 }}
          status="success"
          format={(percent) => `${percent}%`}
        />
        <SurveyFooterRight>
          <BaseStyledButton
            style={{ display: "flex", marginRight: 8 }}
            category="secondary"
            onClick={() => onPressNext(number - 1)}
          >
            <ShapeBack />
          </BaseStyledButton>
        </SurveyFooterRight>
      </SurveyFooter>
    </SurveyBackContainer2>
  );
};

SurveySubmit.propTypes = {
  survey: PropTypes.object,
  onPressNext: PropTypes.func,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SurveySubmit.defaultProps = {
  onPressNext: () => {},
};

export default withLocalize(SurveySubmit);
