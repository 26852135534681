import React from "react";
import BaseButton from "../../components/generic/buttons/BaseButton";
import Logo from "../../assets/icons/logo.svg";
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledCompanyLogo,
  StyledNotFound,
  StyledNotFoundGroup,
  StyledSubTitle,
  StyledRightContainer,
  StyledLeftContainer,
} from "./NotFoundStyles";

const NotFound = ({ history }) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledLeftContainer>
        <StyledNotFound>404</StyledNotFound>
        <StyledCompanyLogo src={Logo} />
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledTitle>Oops, </StyledTitle>
        <StyledSubTitle>Página não encontrada!</StyledSubTitle>
        <StyledDescription>
          Pedimos desculpa pelo inconveniente. Pelo que parece, a página que
          estás a tentar encontrar foi apagada ou então nunca existiu.
        </StyledDescription>
        <BaseButton
          disabled={false}
          loading={false}
          text={"Voltar para a página incial"}
          onClick={() => history.push("/")}
          type={"primary"}
        />
      </StyledRightContainer>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
);

export default NotFound;
