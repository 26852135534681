import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import React from "react";
import { LanguagesComponent, InputLabelDiv, InputField } from "./InputStyles";

const getLabel = (code) => {
  if (code === "en") return "English";
  if (code === "pt") return "Portuguese";
  if (code === "de") return "German";
  if (code === "fr") return "French";
  if (code === "it") return "Italian";
  return "";
};

const SetDefaultObject = (value, languages) => {
  const result = {};
  languages.forEach(
    (l) => (result[l.code] = value && value[l.code] ? value[l.code] : "")
  );
  return result;
};

const TitleInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  disabled,
  meta,
  errors,
  error,
}) => {
  const defaultObject = SetDefaultObject(input.value, languages);

  const showError = meta.invalid && meta.submitFailed;

  const applyError = (key) => {
    const exist = meta.error && Object.keys(meta.error).find((x) => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const value = event?.target?.value;
    defaultObject[code] = value;
    input.onChange(defaultObject);
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      {languages.map((l) => (
        <InputField
          language={1}
          key={l.code}
          disabled={disabled}
          prefix={getLabel(l.code)}
          type="text"
          value={input.value[l.code]}
          onChange={(e) => changeInput(e, l.code)}
          placeholder={placeholder}
          error={applyError(l.code) && meta.submitFailed ? 1 : 0}
        />
      ))}
    </LanguagesComponent>
  );
};

TitleInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

export default withLocalize(TitleInput);
