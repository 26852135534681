import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import React from "react";
import TextAreaInput from "./TextAreaInput";
import DraftInput from "./DraftInput";
import {
  LanguagesComponent,
  InputLabelDiv,
  FormCollapse,
  FormPanel,
} from "./InputStyles";

const getLabel = (label, code) => {
  if (code === "en") return "English";
  if (code === "pt") return "Portuguese";
  if (code === "de") return "German";
  if (code === "fr") return "French";
  if (code === "it") return "Italian";
  return "";
};

const SetDefaultObject = (value, languages) => {
  const result = {};
  languages.forEach(
    (l) => (result[l.code] = value && value[l.code] ? value[l.code] : "")
  );
  return result;
};

const LanguagesInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  meta,
  errors,
  error,
  type,
}) => {
  const defaultObject = SetDefaultObject(input.value, languages);

  const showError = meta.invalid && meta.submitFailed;

  const applyError = (key) => {
    const exist = meta.error && Object.keys(meta.error).find((x) => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const value = event?.target?.value;
    defaultObject[code] = value;
    input.onChange(defaultObject);
  };

  const renderInput = (code) => {
    if (type === "draft")
      return (
        <DraftInput
          noLabel
          meta={{
            invalid: applyError(code),
            submitFailed: meta.submitFailed,
          }}
          placeholder={placeholder}
          input={{
            value: input?.value?.[code],
            onChange: (value) => changeInput(value, code),
          }}
        />
      );

    return (
      <TextAreaInput
        noLabel
        meta={{
          invalid: applyError(code),
          submitFailed: meta.submitFailed,
        }}
        placeholder={placeholder}
        input={{
          value: input?.value?.[code],
          onChange: (e) => changeInput(e, code),
        }}
      />
    );
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      <FormCollapse accordion>
        {languages.map((l) => (
          <FormPanel
            error={applyError(l.code)}
            header={getLabel(label, l.code)}
            key={l.code}
          >
            {renderInput(l.code)}
          </FormPanel>
        ))}
      </FormCollapse>
    </LanguagesComponent>
  );
};

LanguagesInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

export default withLocalize(LanguagesInput);
