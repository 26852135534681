import React from "react";
import { CategoryContainer, CategoryNumber } from "./Styles";
import { Row } from "antd";
import { Col } from "antd";
import { Field } from "redux-form";
import TitleInput from "../../components/generic/inputs/TitleInput";
import LanguagesInput from "../../components/generic/inputs/LanguagesInput";

const CategoriesList = ({ fields }) =>
  fields.map((field, index) => {
    return (
      <CategoryContainer key={index}>
        <CategoryNumber>Category number {index + 1}</CategoryNumber>
        <Row gutter={24}>
          <Col xs={24}>
            <Field
              component={TitleInput}
              name={`${field}.title`}
              type="text"
              label="Title"
              placeholder={"Insert the title"}
            />
          </Col>
          <Col xs={24}>
            <Field
              component={LanguagesInput}
              name={`${field}.description`}
              type="text"
              label="Description"
              placeholder={"Insert the description"}
            />
          </Col>
          <Col xs={24}>
            <Field
              component={LanguagesInput}
              name={`${field}.question`}
              type="text"
              label="Question"
              placeholder={"Insert the question"}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={LanguagesInput}
              name={`${field}.collaborate`}
              type="text"
              label="A) Collaborate Option"
              placeholder={"Insert the collaborate option"}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={LanguagesInput}
              name={`${field}.create`}
              type="text"
              label="B) Create Option"
              placeholder={"Insert the create question"}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={LanguagesInput}
              name={`${field}.compete`}
              type="text"
              label="C) Compete Option"
              placeholder={"Insert the compete option"}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={LanguagesInput}
              name={`${field}.control`}
              type="text"
              label="D) Control Option"
              placeholder={"Insert the control option"}
            />
          </Col>
        </Row>
      </CategoryContainer>
    );
  });

export default CategoriesList;
