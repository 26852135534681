import React, { Component } from "react";
import { connect } from "react-redux";
import { Margin, PageContainer, SpinLoading } from "../../styles/BasicStyles";
import { GetClientsDropdown } from "../../infra/requests/ClientRequests";
import {
  GetCulturalAssessmentsDropdown,
  GetAssessmentResults,
} from "../../infra/requests/CulturalAssessmentsRequests";
import { Row, Col } from "antd";
import SelectInput from "../../components/generic/inputs/SelectInput";
import LegalEntities from "../../infra/utils/LegalEntities";
import Roles from "../../infra/utils/Roles";
import CategorySections from "./CategorySections";

class DashboardPage extends Component {
  state = {
    ready: false,
    loading: false,
    data: [],
    clients: [],
    assessments: [],
    selected: undefined,
    client: undefined,
    entity: [],
  };

  componentDidMount = async () => {
    const clients = await GetClientsDropdown();
    const assessments = await GetCulturalAssessmentsDropdown();
    const list = assessments?.data || [];
    let selected;
    if (list.length) selected = list[0]._id;
    this.setState({
      ready: true,
      clients: clients?.data || [],
      assessments: list,
      selected,
    });
  };

  loadAssessments = async () => {
    const { selected, client, entity, role } = this.state;
    if (selected && client) {
      this.setState({ loading: true });
      const result = await GetAssessmentResults(selected, client, entity, role);
      this.setState({ loading: false, data: result?.data || [] });
    }
  };

  render() {
    const {
      ready,
      assessments,
      selected,
      clients,
      client,
      entity,
      role,
      data,
      loading,
    } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <PageContainer left>
        <Row gutter={24}>
          <Col xs={6}>
            <SelectInput
              label="Assessment"
              placeholder="Select assessment"
              input={{
                value: selected,
                onChange: (v) =>
                  this.setState({ selected: v }, this.loadAssessments),
              }}
              meta={{ valid: true }}
              translatable
              data={assessments}
            />
          </Col>
          <Col xs={6}>
            <SelectInput
              label="Client"
              placeholder="Select client"
              allowClear
              input={{
                value: client,
                onChange: (v) =>
                  this.setState({ client: v }, this.loadAssessments),
              }}
              meta={{ valid: true }}
              data={clients}
            />
          </Col>
          <Col xs={6}>
            <SelectInput
              label="Workplace"
              placeholder="All"
              mode="multiple"
              input={{
                value: entity,
                onChange: (v) =>
                  this.setState({ entity: v }, this.loadAssessments),
              }}
              allowClear
              meta={{ valid: true }}
              data={LegalEntities}
            />
          </Col>
          <Col xs={6}>
            <SelectInput
              label="Role"
              placeholder="All"
              allowClear
              input={{
                value: role,
                onChange: (v) =>
                  this.setState({ role: v }, this.loadAssessments),
              }}
              meta={{ valid: true }}
              data={Roles}
            />
          </Col>
          <Col xs={24}>
            <CategorySections
              data={data}
              loading={loading}
              assessment={assessments.find((c) => c._id == selected)}
              client={clients.find((c) => c._id == client)}
              role={Roles.find((c) => c._id == role)}
              entity={entity}
            />
          </Col>
        </Row>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  info: state.info,
});

export default connect(mapStateToProps)(DashboardPage);
