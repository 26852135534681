import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { initialize, Field, FieldArray, reduxForm } from "redux-form";
import ManageFormHeader from "../../components/base/ManageFormHeader";
import {
  GetCulturalAssessment,
  EditCulturalAssessment,
} from "../../infra/requests/CulturalAssessmentsRequests";
import FormValidator from "../../infra/services/validations/FormValidator";
import { BaseForm, FormContainer, SpinLoading } from "../../styles/BasicStyles";
import { FlattenToFormData } from "../../infra/services/formdata/TransformToFormData";
import { Col, Row } from "antd";
import TitleInput from "../../components/generic/inputs/TitleInput";
import LanguagesInput from "../../components/generic/inputs/LanguagesInput";
import CategoriesList from "./CategoriesList";
import { CategoriesTitle } from "./Styles";

const validations = FormValidator.make({
  name: "required",
  country: "required",
  address: "required",
  city: "required",
  postal_code: "required",
});

class ManageCulturalAssessments extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
    } = this.props;

    if (params.id) {
      const { data, success } = await GetCulturalAssessment(params.id);
      if (success) {
        dispatch(initialize("manage_cultural_assessment_form", { ...data }));
      }
      this.setState({
        ready: true,
        loading: false,
        isNew: false,
        info: data,
      });
    } else {
      this.setState({
        loading: false,
        ready: true,
        isNew: true,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;

      const payload = FlattenToFormData({ ...values });
      await EditCulturalAssessment(params.id, payload);
      this.setState({ loading: false });
      return history.push("/cultural-assessments");
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push("/cultural-assessments");
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, ready } = this.state;

    if (!ready) return <SpinLoading />;

    const title = isNew ? "Add" : "Edit";

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={["Cultural Assessments", title]}
          buttons={[
            {
              type: "primary",
              icon: "save",
              text: "Save",
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: "default",
              icon: "close",
              text: "Cancel",
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TitleInput}
                  name="name"
                  type="text"
                  label="Name"
                  placeholder={"Insert the  name of the assessment"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={LanguagesInput}
                  name="intro"
                  type="text"
                  label="Introduction Text"
                  placeholder={"Insert the introduction text"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={LanguagesInput}
                  name="instructions"
                  type="text"
                  label="Instructions"
                  placeholder={"Insert the instructions"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={LanguagesInput}
                  name="note"
                  type="text"
                  label="Thank You Note"
                  placeholder={"Insert the thank you note"}
                />
              </Col>
              <Col xs={24}>
                <CategoriesTitle>Categories</CategoriesTitle>
                <FieldArray component={CategoriesList} name="chapters" />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCulturalAssessments = reduxForm({
  form: "manage_cultural_assessment_form",
  validate: validations,
})(ManageCulturalAssessments);

export default withLocalize(ManageCulturalAssessments);
