import React, { Component } from "react";
import FormValidator from "../../infra/services/validations/FormValidator";
import {
  LoginContainer,
  LoginCard,
  StyledLogoContainer,
  StyleLogoImg,
  ErrorSection
} from "./AuthStyles";
import { Icon } from "antd";
import { RecoverAccount, ValidateAccount } from "../../infra/requests/AuthRequests";
import Logo from '../../assets/icons/logo_b.svg';
import { SpinLoading } from "../../styles/BasicStyles";
import queryString from "query-string";

const formRules = FormValidator.make({
  email: "required|email"
});

class ValidateAccountPage extends Component {
  state = {
    loading: true,
    error: false,
    success: false,
  };

  componentDidMount() {
    this.submitValidation();
  }

  submitValidation = async () => {
    try {
      const {
        location: { search },
      } = this.props;
      const query = queryString.parse(search);
      const result = await ValidateAccount({
        id: query.id,
        email: query.email,
        activate_token: query.token
      });
      if (result && result.success)
        this.setState({ loading: false, success: true });
      else this.setState({ error: true, loading: false });
    } catch (e) {
      this.setState({ error: true, loading: false });
    }
  };


  renderContent = () => {
    const { loading, error, success } = this.state;
    if (loading) return <SpinLoading />;
    if (error)
      return <ErrorSection>Não foi possível validar a sua conta</ErrorSection>;
    if (success)
      return (
      <>
        <ErrorSection>Conta validada com sucesso!</ErrorSection>
        
      </>
      );
    return null;
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginCard>
          <StyledLogoContainer>
            <StyleLogoImg src={Logo}/>
          </StyledLogoContainer>
          {this.renderContent()}
        </LoginCard>
      </LoginContainer>
    );
  }
}

export default ValidateAccountPage;
