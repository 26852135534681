import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  margin: 0;
  font-weight: 100;

  &&& .ant-pagination {
    float: left;
  }

  table {
    background-color: #fff;
    tr {
      th {
        color: ${(p) =>
          p.secondary ? "#565656" : p.theme.primaryBackgroundColor};
        background-color: ${(p) =>
          p.secondary
            ? p.theme.secondaryBackgroundColor
            : p.theme.secondaryColor} !important;
      }
    }

    td {
      max-width: 400px;
      vertical-align: ${({ alignContentTop }) =>
        alignContentTop ? "top" : "inherit"};
    }
  }

  .ant-table-tbody > tr {
    cursor: ${(p) => (p.onPressRow ? "default" : "pointer")};
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: 0px;
    display: inline-block;
    width: 100%;
  }
`;
