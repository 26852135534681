import styled from "styled-components";
import { PrimaryColour, SecondaryColour } from "../../styles/Colours";

export const ChartSection = styled.div`
  padding: 30px;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
`;

export const GraphicContainer = styled.div`
  width: 500px;
  height: 500px;
  position: relative;
  display: inline-block;
`;

export const GraphicHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
`;

export const TableContainer = styled.div`
  width: 400px;
  display: inline-block;
`;

export const TotalLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  span: {
    font-size: 22px;
  }
`;

export const EmptyState = styled.div`
  margin-top: 120px;
  font-size: 32px;
  text-align: center;
  opacity: 0.6;
  i {
    margin-bottom: 30px;
    font-size: 140px;
  }
`;

export const Preview = styled.div`
  display: inline-block;
  width: 100%;
  padding: 10px;
`;

export const PreviewHeader = styled.div`
  display: inline-block;
  width: 100%;
`;

export const PreviewTitle = styled.h1`
  margin-top: 30px;
  text-align: center;
`;

export const PreviewSubtitle = styled.h2`
  margin-top: 10px;
  text-align: left;
  span {
    color: ${PrimaryColour};
  }
`;

export const PreviewDescription = styled.div`
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  span {
    color: ${PrimaryColour};
  }
`;

export const GraphicTitle = styled.h3`
  margin-top: 30px;
  opacity: 0.6;
`;
