import client from "../config/AxiosConfig";

export const GetClientsDropdown = async () => await client.get("/clients");

export const GetClients = async (currentPage, limit, filters) =>
  await client.get(`/clients/${currentPage}/${limit}?filter=${filters}`);

export const GetClient = async (id) => await client.get(`/clients/${id}`);

export const CreateClient = async (data) => await client.post(`/clients`, data);

export const EditClient = async (id, data) =>
  await client.put(`/clients/${id}`, data);

export const ToggleClient = async (id, active) =>
  await client.put(`/clients/${id}/toggle`, { active });
