import React, { Component } from "react";
import Table from "../../components/generic/table/Table";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from "../../styles/BasicStyles";
import { GetUsers } from "../../infra/requests/UsersRequests";
import AdminFilters from "./AdminFilters";

class AdminsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: "",
    loading: true,
    ready: false,
    columns: [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
    ],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetUsers(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/administrators/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Administrators</PageTitle>
            {`Platform administrators`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <AdminFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader
            emptyIcon="warning"
            emptyText={"There are no administrators"}
            total={total}
            rowKey={"_id"}
            hasPagination
            // onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default AdminsPage;
