import React from "react";

import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

import {
  BaseStyledButton,
  SurveyBackContainer2,
  SurveyBox,
  SurveyFooter,
  SurveyFooterRight,
  SurveyHeader,
  SurveyImage,
  SurveyInfoImagePlaceholder,
  SurveyScroll2,
} from "./SurveysStyles";
import {
  BodySmall2Survey,
  BodySurvey,
  ButtonTitle,
  TitleSurveyH5,
} from "../../styles/TextSizes";
import { ReactComponent as Shape } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as Play } from "../../assets/icons/play-button.svg";
import { Col, Row } from "antd";
import { getImage, GetValuePreview } from "../../infra/utils/CommonFunctions";
import Language from "../../components/generic/language/Language";
import VideoPlayer from "../../components/generic/video/VideoPlayer";
import Survey_Video_EN from "../../assets/videos/survey/survey_en.mp4";
import Survey_Video_DE from "../../assets/videos/survey/survey_de.mp4";

const SurveyIntro = ({ survey, onPressNext, activeLanguage, translate }) => {
  const getVideoForActiveLanguage = () => {
    if (activeLanguage?.code === "en") {
      return Survey_Video_EN;
    }

    if (activeLanguage?.code === "de") {
      return Survey_Video_DE;
    }

    if (activeLanguage?.code === "fr") {
      return Survey_Video_EN;
    }

    if (activeLanguage?.code === "it") {
      return Survey_Video_EN;
    }
  };

  return (
    <SurveyBackContainer2>
      <SurveyHeader>
        <TitleSurveyH5 color="#ffffff" style={{ marginBottom: 0 }}>
          {translate("SURVEY_INTRODUCTION")}
        </TitleSurveyH5>
        <Language />
      </SurveyHeader>
      <SurveyScroll2>
        <SurveyBox>
          <Row gutter={[12, 12]}>
            <Col lg={12} xs={24}>
              <BodySmall2Survey style={{ marginBottom: 8 }}>
                {translate("INSTRUCTIONS")}
              </BodySmall2Survey>
              <TitleSurveyH5 style={{ marginBottom: 24 }}>
                {translate("HOW_TO_ANSWER_THE_QUESTIONS")}
              </TitleSurveyH5>
              <BodySurvey style={{ whiteSpace: "pre-wrap" }}>
                {GetValuePreview(survey.instructions, activeLanguage.code)}
              </BodySurvey>
            </Col>
            <Col lg={12} xs={24}>
              <VideoPlayer width={"100%"} video={getVideoForActiveLanguage()} />
            </Col>
          </Row>
        </SurveyBox>
      </SurveyScroll2>
      <SurveyFooter>
        <div />
        <SurveyFooterRight>
          <BaseStyledButton
            style={{ display: "flex" }}
            category="primary"
            onClick={() => onPressNext(1)}
          >
            <ButtonTitle>{translate("START")} </ButtonTitle>
            <Shape style={{ marginLeft: 8 }} />
          </BaseStyledButton>
        </SurveyFooterRight>
      </SurveyFooter>
    </SurveyBackContainer2>
  );
};

SurveyIntro.propTypes = {
  survey: PropTypes.object,
  onPressNext: PropTypes.func,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

SurveyIntro.defaultProps = {
  onPressNext: () => {},
};

export default withLocalize(SurveyIntro);
