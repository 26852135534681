import { Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { withLocalize } from "react-localize-redux";
import { TextColor } from "../../../styles/Colours";
import { BodySmall2Survey, TextHeader2Survey } from "../../../styles/TextSizes";
import { InputDiv, StyledSelectInputSurvey } from "./InputStyles";
const Option = Select.Option;

const SurveySelectInput = ({
  input,
  meta,
  subLabel,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  label,
  disabled,
  fetchData,
  loading,
  first,
  translatable,
  activeLanguage,
  allowClear,
  execAfterChange,
  translate
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = (value) => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = (value) => {
    input.onChange(value || "");
    setTimeout(function() {
      if (typeof execAfterChange === "function") {
        execAfterChange(value);
      }
    }, 200)
  };

  return (
    <InputDiv first={first}>
      {subLabel && (<BodySmall2Survey style={{marginBottom: 8}}>{subLabel}</BodySmall2Survey>)}
      {label && (<TextHeader2Survey style={{marginBottom: 16, color: TextColor}}>{label}</TextHeader2Survey>)}
      <StyledSelectInputSurvey
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        optionLabelProp="label"
        showArrow={true}
        showSearch={true}
        loading={loading}
        error={showError ? 1 : 0}
      >
        {data.map((current, index) => (
          <Option
            key={current[dataKey]}
            disabled={current.disabled}
            label={
              translatable
                ? translate(current[dataLabel].toUpperCase())
                : current[dataLabel]
            }
          >
            {translatable
              ? translate(current[dataLabel].toUpperCase())
              : current[dataLabel]}
          </Option>
        ))}
      </StyledSelectInputSurvey>
    </InputDiv>
  );
};

SurveySelectInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default withLocalize(SurveySelectInput);
