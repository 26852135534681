export const PrimaryColour = "#387ef5";
export const SecondaryColour = "#689cc5";
export const ThirdColour = "#7eadff";
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColour = '#ED1D24';
export const InputBorderColour = '#979797';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#ED1D24';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#ffffff';
export const SecondaryBackgroundColor = '#f3f3f3';
export const ThirdBackgroundColor = '#cccccc';
export const LightBackgroundColor = '#edf1fa';
export const HeaderBackgroundColor = '#e8e8e8';
export const TitleColor = '#3378c8';
export const TextColor = '#0c0c0c';
export const ButtonColor = '#17ac9d';
export const SuccessButtonColor = '#069f22';
export const SuccessColor = "#07bc0c";
export const ErrorColor = "#D8000C";
export const SurveyInputPrimaryBackground = "#dbe3f4";
