import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LoginButtonDiv,
  FormTitle,
  BaseLink,
  StyledLogoContainer,
  StyleLogoImg
} from "./AuthStyles";
import { Icon, notification } from "antd";
import { RecoverAccount } from "../../infra/requests/AuthRequests";
import Logo from '../../assets/icons/logo_b.svg';

const formRules = FormValidator.make({
  email: "required|email"
});

class RecoverAccountPage extends Component {
  onSubmit = async values => {
    const { history } = this.props;
    try {
      const { success } = await RecoverAccount(values);
      if (success) {
        notification.success({
          message: "Pedido enviado!",
          description: "Irá receber um email para redefinir a nova password."
        });
        history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <StyledLogoContainer>
              <StyleLogoImg src={Logo}/>
            </StyledLogoContainer>
            <FormTitle>Recuperar conta</FormTitle>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="Insira o seu email"
              prefix={<Icon type="mail" />}
            />
            <BaseLink to="/login">Voltar para o login</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Enviar"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default reduxForm({
  form: "recover_account",
  validate: formRules
})(RecoverAccountPage);
