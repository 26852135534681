import { renderToStaticMarkup } from "react-dom/server";
import { addTranslationForLanguage, initialize } from "react-localize-redux";
import { GetInfo, GetTranslations } from "../../infra/requests/BaseRequests";
import { SaveInfo } from "../Info/info.actions";
import { SaveUser } from "../User/user.actions";

const getName = (code) => {
  if (code === "pt") return "Portuguese";
  if (code === "en") return "English";
  if (code === "de") return "German";
  if (code === "fr") return "French";
  if (code === "it") return "Italian";
  return "Unknown Language";
};

const defineLangNames = (list = []) => {
  return list.map((lang) => ({ code: lang, name: getName(lang) }));
};

const DefineLanguage = (languages, default_language) => {
  const cookieLang = localStorage.getItem("LANG_COOKIE");
  if (cookieLang && languages.find((x) => x.code === cookieLang))
    return cookieLang;
  const browserLang = navigator.language || navigator.userLanguage;
  if (browserLang) {
    const code = browserLang.substring(0, 2);
    if (languages.find((x) => x.code === code)) return code;
  }
  if (default_language) return default_language;
  return "en";
};

export default function start() {
  return async (dispatch) => {
    try {
      const info = await GetInfo();
      const dataTranslations = await GetTranslations();

      const languages =
        info?.data?.languages?.length > 0 ? info?.data?.languages : ["en"];

      dispatch(SaveUser(info.data?.user));
      dispatch(SaveInfo(info?.data));

      const defaultLanguage = DefineLanguage(
        languages,
        info.data.default_language
      );

      dispatch(
        initialize({
          languages: defineLangNames(languages),
          translation: [],
          options: {
            renderToStaticMarkup,
            defaultLanguage: defaultLanguage,
          },
        })
      );

      languages.map((code) =>
        dispatch(addTranslationForLanguage(dataTranslations.data[code], code))
      );
    } catch (e) {
      console.error(e);
    }
  };
}
