import client from "../config/AxiosConfig";

export const GetUsers = async (currentPage, limit, filters) =>
  await client.get(`/users/${currentPage}/${limit}?filter=${filters}`);

export const GetUser = async (id) => await client.get(`/users/${id}`);

export const CreateUser = async (data) => await client.post(`/users`, data);

export const EditUser = async (id, data) =>
  await client.put(`/users/${id}`, data);
