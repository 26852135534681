import {
  PrimaryColour,
  SecondaryColour,
  ThirdColour,
  PrimaryCTA,
  SecondaryCTA,
  AlertColour,
  InputBorderColour,
  PrimaryBorderColor,
  SecondaryBorderColor,
  MenuBorderColor,
  PrimaryBackgroundColor,
  SecondaryBackgroundColor,
  ThirdBackgroundColor,
  HeaderBackgroundColor,
  LightBackgroundColor,
  TitleColor,
  TextColor
} from "./Colours";

export default {
  //colors
  primaryColor: PrimaryColour,
  secondaryColor: SecondaryColour,
  thirdColor: ThirdColour,
  primaryCTA: PrimaryCTA,
  secondaryCTA: SecondaryCTA,
  titleColor: TitleColor,
  textColor: TextColor,

  inputBorderColor: InputBorderColour,
  inputFocusColor: InputBorderColour,
  inputErrorColor: AlertColour,

  primaryBorderColor: PrimaryBorderColor,
  secondaryBorderColor: SecondaryBorderColor,
  menuBorderColor: MenuBorderColor,

  primaryBackgroundColor: PrimaryBackgroundColor,
  secondaryBackgroundColor: SecondaryBackgroundColor,
  thirdBackgroundColor: ThirdBackgroundColor,
  lightBackgroundColor: LightBackgroundColor,

  headerBackgroundColor: HeaderBackgroundColor,

  //text size
  titleSize: "24px",
  sectionSize: "20px",
  textSize: "14px"
};
