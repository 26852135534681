export const GetAvailableLanguages = () => ["en", "de", "fr", "it"];

export const Languages = [
  { code: "en", name: "English" },
  { code: "de", name: "German" },
  { code: "fr", name: "French" },
  { code: "it", name: "Italian" },
];

export const GetTranslationDescription = (code) =>
  Languages.find((x) => x.code === code)?.name;

export const TranslateFromObject = (object, code) => {
  if (!object) return "";
  if (object[code]) return object[code];
  if (object["en"]) return object["en"];
  if (Object.keys(object).length) return object[Object.keys(object)[0]];
  return object;
};
