import client from "../config/AxiosConfig";

export const GetCulturalAssessmentsDropdown = async () =>
  await client.get("/cultural-assessments");

export const GetCulturalAssessments = async (currentPage, limit, filters) =>
  await client.get(
    `/cultural-assessments/${currentPage}/${limit}?filter=${filters}`
  );

export const GetCulturalAssessment = async (id) =>
  await client.get(`/cultural-assessments/${id}`);

export const EditCulturalAssessment = async (id, data) =>
  await client.put(`/cultural-assessments/${id}`, data);

export const GetAssessmentResults = async (
  id,
  clientId,
  entity = [],
  role = ""
) =>
  await client.get(
    `/results/${id}/${clientId}?entity=${JSON.stringify(entity)}&role=${role}`
  );
