import styled from "styled-components";

export const CategoriesTitle = styled.h1`
  margin-top: 30px;
`;

export const CategoryContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background: #fafafa;
`;

export const CategoryNumber = styled.h3``;
