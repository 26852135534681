import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LoginButtonDiv,
  BaseLink,
  StyledLogoContainer,
  StyleLogoImg,
} from "./AuthStyles";
import { Icon } from "antd";
import { Login } from "../../infra/requests/AuthRequests";
import { SaveUser } from "../../redux/User/user.actions";
import { auth_token_key } from "../../infra/config/LocalStorageKeys";
import { isEmpty } from "lodash";
import Logo from "../../assets/icons/logo_b.svg";

const formRules = FormValidator.make({
  email: "required|email",
  password: "required|min:6",
});

class LoginPage extends Component {
  constructor(props) {
    super(props);
    // validate if user already exists
    if (!isEmpty(props.user)) props.history.push("/");
  }

  onSubmit = async (values) => {
    try {
      const { SaveUser, history } = this.props;
      const result = await Login(values);
      if (result.success) {
        localStorage.setItem(auth_token_key, result.data.token);
        SaveUser(result.data.user);
        history.push("/");
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <StyledLogoContainer>
              <StyleLogoImg src={Logo} />
            </StyledLogoContainer>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="Insert your email"
              prefix={<Icon type="mail" />}
            />
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="Password"
              placeholder="Insert your password"
              prefix={<Icon type="lock" />}
            />
            <BaseLink to="/recover-account">Recover account</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Login"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

LoginPage = reduxForm({
  form: "login",
  validate: formRules,
})(LoginPage);

const mapStateToProps = (state, ownProps) => ({ user: state.user });
const mapActionToProps = (dispatch) =>
  bindActionCreators({ SaveUser }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(LoginPage);
