import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LoginButtonDiv,
  FormTitle,
  StyledLogoContainer,
  StyleLogoImg,
  ErrorSection
} from "./AuthStyles";
import { Icon, notification } from "antd";
import { ResetPassword } from "../../infra/requests/AuthRequests";
import queryString from "query-string";
import Logo from '../../assets/icons/logo_b.svg';
const REACT_APP_LINK = process.env.REACT_APP_LINK;

const ValidateResetForm = values => {
  const errors = {};
  errors.password = FormValidator.validateField(
    "password",
    values.password,
    "required|min:6"
  );
  errors.passwordconfirm = FormValidator.validateField(
    "passwordconfirm",
    values.passwordconfirm,
    "required|min:6"
  );
  if (values.password !== values.passwordconfirm) {
    errors.passwordconfirm = new Error("Invalid password");
  }
  return errors;
};

class ResetPasswordPage extends Component {
  state = {
    success: false,
    bo: false,
  };

  componentDidMount() {
    const {
      location: { search },
      dispatch,
    } = this.props;
    const query = queryString.parse(search);
    this.setState({ bo: query.role == "1" || false });
  }

  onSubmit = async values => {
    const {
      location: { search },
      history
    } = this.props;
    const params = queryString.parse(search);
    const newParams = {
      password: values.password,
      token: params.token,
    };

    try {
      const result = await ResetPassword(newParams);
      if (result && result.success) {
        this.setState({ success: true });
        notification.success({
          message: "Senha alterada!",
          description:
            "Pode voltar a entrar com a nova senha."
        });
        if (params.role == "1") history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  renderContent() {
    const { submitting, history } = this.props;
    const { success, bo } = this.state;
    if (success)
      return (
        <>
        <ErrorSection>
          Password alterada com sucesso 
        </ErrorSection>
          {bo ? (
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="button"
                type="primary"
                text='Ir para o login'
                onClick={() => history.push("/login")}
              />
            </LoginButtonDiv>
          ) : (
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                type="primary"
                text='Ir para o login'
                onClick={() => window.open(
                  REACT_APP_LINK,
                  '_blank'
                )}
              />
            </LoginButtonDiv>
          )}
        </>
      );

    return (
      <Fragment>
        <FormTitle>Redefinir a nova senha</FormTitle>
        <Field
              component={TextInput}
              name="password"
              type="password"
              label="Nova senha"
              placeholder="Insira a nova senha"
              prefix={<Icon type="lock" />}
            />
            <Field
              component={TextInput}
              name="passwordconfirm"
              type="password"
              label="Confimar a nova senha"
              placeholder="Repita a nova senha"
              prefix={<Icon type="lock" />}
            />
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Alterar senha"
              />
            </LoginButtonDiv>
      </Fragment>
    );
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <StyledLogoContainer>
              <StyleLogoImg src={Logo}/>
            </StyledLogoContainer>
            {this.renderContent()}
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default reduxForm({
  form: "reset_password",
  validate: ValidateResetForm
})(ResetPasswordPage);
