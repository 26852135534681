export default [
  {
    type: "menu",
    name: "Dashboard",
    to: "/",
    icon: "radar-chart",
    exact: true,
  },
  {
    type: "menu",
    name: "Administrators",
    to: "/administrators",
    icon: "user",
    exact: false,
  },
  {
    type: "menu",
    name: "Clients",
    to: "/clients",
    icon: "bank",
    exact: false,
  },
  {
    type: "menu",
    name: "Cultural Assessments",
    to: "/cultural-assessments",
    icon: "snippets",
    exact: false,
  },
  // {
  //   type: 'menu',
  //   name: 'Email templates',
  //   to: '/email-templates',
  //   icon: 'mail',
  //   exact: false
  // },
  {
    type: "menu",
    name: "Translations",
    to: "/translations",
    icon: "global",
    exact: true,
  },
];
