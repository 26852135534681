import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const VideoPlayer = ({video, width, height}) => (
    <ReactPlayer url={video} height={height} width={width} controls />
)

VideoPlayer.propTypes = {
  radius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

VideoPlayer.defaultProps = {
  height: '400px',
  width: '600px'
};

export default VideoPlayer;
