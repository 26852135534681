import React from "react";
import { SpinLoading } from "../../styles/BasicStyles";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from "recharts";
import {
  ChartSection,
  GraphicContainer,
  GraphicHover,
  TableContainer,
  TotalLabel,
  EmptyState,
} from "./DashboardStyles";
import Table from "../../components/generic/table/Table";
import { Row, Col, Icon } from "antd";
import { BaseButton } from "../../components/generic/buttons/BaseButton";
import LegalEntities from "../../infra/utils/LegalEntities";

const fields = [
  { field: "Collaborate" },
  { field: "Flexibility" },
  { field: "Create" },
  { field: "External" },
  { field: "Compete" },
  { field: "Stability" },
  { field: "Control" },
  { field: "Internal" },
];

const Labels = ["Collaborate", "Create", "Compete", "Control"];

const columns = [
  {
    title: "Values",
    dataIndex: "field",
  },
  {
    title: "Culture",
    dataIndex: "culture",
  },
  {
    title: "Present",
    dataIndex: "present",
  },
  {
    title: "Future",
    dataIndex: "future",
  },
  {
    title: "Difference",
    render: (data) => {
      let diff = data.future - data.present;
      if (diff < 0) diff = diff * -1;
      return diff;
    },
  },
];

const TriggerImageDownload = (imgURI, fileName) => {
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: false,
    cancelable: true,
  });
  const a = document.createElement("a");
  a.setAttribute("download", fileName);
  a.setAttribute("href", imgURI);
  a.setAttribute("target", "_blank");
  a.dispatchEvent(evt);
};

const PrepareSVG = (svg) => {
  try {
    const position = 4;
    const added = ' xmlns="http://www.w3.org/2000/svg"';
    const complete = [
      svg.outerHTML.slice(0, position),
      added,
      svg.outerHTML.slice(position),
    ].join("");
    return complete;
  } catch (e) {
    throw e;
  }
};

const LoadImage = (svg) =>
  new Promise((resolve) => {
    const DOMURL = window.URL || window.webkitURL || window;
    var img = new Image();
    const blob = new Blob([svg], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = DOMURL.createObjectURL(blob);
    img.onload = function () {
      resolve(img);
    };
    img.src = url;
  });

const constructData = (data, category) => {
  let rows = 0;
  const params = [];
  const collaborate = { present: 0, future: 0 };
  const create = { present: 0, future: 0 };
  const compete = { present: 0, future: 0 };
  const control = { present: 0, future: 0 };

  if (Array.isArray(data)) {
    data.forEach((form) => {
      if (Array.isArray(form.chapters)) {
        if (category >= 0) {
          const answer = form.chapters[category];
          if (answer) {
            collaborate.present += answer.present.collaborate;
            collaborate.future += answer.future.collaborate;
            create.present += answer.present.create;
            create.future += answer.future.create;
            compete.present += answer.present.compete;
            compete.future += answer.future.compete;
            control.present += answer.present.control;
            control.future += answer.future.control;
            rows++;
          }
        } else {
          form.chapters.forEach((chapter) => {
            collaborate.present += chapter.present.collaborate;
            collaborate.future += chapter.future.collaborate;
            create.present += chapter.present.create;
            create.future += chapter.future.create;
            compete.present += chapter.present.compete;
            compete.future += chapter.future.compete;
            control.present += chapter.present.control;
            control.future += chapter.future.control;
            rows++;
          });
        }
      }
    });
  }

  const max =
    Math.max(
      collaborate.present,
      collaborate.future,
      create.present,
      create.future,
      compete.present,
      compete.future,
      control.present,
      control.future
    ) / rows;

  const domain = Math.ceil(max / 10) * 10;

  params.push({
    field: "Collaborate",
    present: parseInt(collaborate.present / rows),
    future: parseInt(collaborate.future / rows),
    culture: "Clan",
  });
  params.push({
    field: "Create",
    present: parseInt(create.present / rows),
    future: parseInt(create.future / rows),
    culture: "Adhocracy",
  });
  params.push({
    field: "Compete",
    present: parseInt(compete.present / rows),
    future: parseInt(compete.future / rows),
    culture: "Market",
  });
  params.push({
    field: "Control",
    present: parseInt(control.present / rows),
    future: parseInt(control.future / rows),
    culture: "Hierarchy",
  });
  return { params, domain };
};

const ChartComponent = ({
  data,
  loading,
  client,
  role,
  entity,
  category,
  hidden,
}) => {
  if (!client)
    return (
      <EmptyState>
        <Icon type="radar-chart" />
        <div>Select a client first</div>
      </EmptyState>
    );

  if (loading) return <SpinLoading />;

  if (data.length === 0)
    return (
      <EmptyState>
        <Icon type="radar-chart" />
        <div>There are no assessments yet</div>
      </EmptyState>
    );

  const { params, domain } = constructData(data, category);

  const DefaultAxis = (
    <PolarRadiusAxis
      stroke="black"
      orientation="left"
      angle={90}
      tick={{ fontSize: 10 }}
      domain={[0, domain]}
    />
  );

  const handleDownloadImage = async () => {
    try {
      const section = document.getElementById(
        `graph-container-${category >= 0 ? category : "all"}`
      );
      const svgList = section.getElementsByTagName("svg");
      const svg1 = PrepareSVG(svgList[0]);
      const svg2 = PrepareSVG(svgList[1]);
      const elem = svgList[0];
      const bounds = elem.getBoundingClientRect();
      const canvas = document.createElement("canvas");
      canvas.width = bounds.width;
      canvas.height = bounds.height;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, bounds.width, bounds.height);
      const img1 = await LoadImage(svg1);
      const img2 = await LoadImage(svg2);
      ctx.drawImage(img1, 0, 0);
      ctx.drawImage(img2, 0, 0);

      ctx.fillStyle = "#387ef5";
      ctx.fillRect(bounds.width / 2 - 80, 20, 14, 14);

      ctx.fillStyle = "#ED1D24";
      ctx.fillRect(bounds.width / 2 + 20, 20, 14, 14);

      ctx.fillStyle = "black";
      ctx.font = "14px Roboto";
      ctx.fillText("Present", bounds.width / 2 - 60, 32);
      ctx.fillText("Future", bounds.width / 2 + 40, 32);

      const imgURI = canvas.toDataURL("image/jpg", 1);
      TriggerImageDownload(
        imgURI,
        `${client?.name || "client"}_${
          category >= 0 ? category + 1 : "all"
        }category_assessment.jpg`
      );
      const DOMURL = window.URL || window.webkitURL || window;
      DOMURL.revokeObjectURL(img1.url);
      DOMURL.revokeObjectURL(img2.url);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadCSV = () => {
    const svgRows = [];

    const entities = entity.length
      ? entity.map((e) => {
          const found = LegalEntities.find((l) => l._id == e);
          return found?.name || "";
        })
      : ["All"];

    svgRows.push(["Client", client?.name || "All"]);
    svgRows.push(["Category", category >= 0 ? `${category + 1}º` : "All"]);
    svgRows.push(["Workplaces", ...entities]);
    svgRows.push(["Role", role?.name || "All"]);
    svgRows.push(["Total Assessments", data.length]);
    svgRows.push([" ", " "]);

    const header = columns.map((c) => c.title);
    svgRows.push(header);
    params.forEach((p) => {
      let diff = p.future - p.present;
      if (diff < 0) diff = diff * -1;
      const row = [p.field, p.culture, p.present, p.future, diff];
      svgRows.push(row);
    });

    const csvContent =
      "data:text/csv;charset=utf-8,\n" +
      svgRows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${client?.name || "client"}_${
        category >= 0 ? category + 1 : "all"
      }category_assessment.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <ChartSection>
      <GraphicContainer
        id={`graph-container-${category >= 0 ? category : "all"}`}
      >
        <RadarChart
          width={500}
          height={500}
          startAngle={135}
          endAngle={-225}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={fields}
        >
          <PolarGrid />
          <PolarAngleAxis
            dataKey="field"
            tick={{ fill: "black", fontSize: 12 }}
          />
          {DefaultAxis}
          <Legend verticalAlign="top" height={36} />
        </RadarChart>

        <GraphicHover>
          <RadarChart
            width={500}
            height={500}
            startAngle={135}
            endAngle={-225}
            cx="50%"
            cy="50%"
            outerRadius="80%"
            data={params}
          >
            {DefaultAxis}
            <Radar
              name="Present"
              dataKey="present"
              stroke="#387ef5"
              fill="#387ef5"
              fillOpacity={0.5}
            />
            <Radar
              name="Future"
              dataKey="future"
              stroke="#ED1D24"
              fill="#ED1D24"
              fillOpacity={0.3}
            />
            <Tooltip labelFormatter={(i) => Labels[i]} />
            <Legend verticalAlign="top" height={36} />
          </RadarChart>
        </GraphicHover>
      </GraphicContainer>
      <TableContainer id="table-container">
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <TotalLabel>
              Total Assessments: <span>{data.length}</span>
            </TotalLabel>
          </Col>
          <Col xs={24}>
            <Table
              columns={columns}
              currentPage={1}
              pageSize={4}
              rows={params}
              showHeader
              total={4}
              rowKey="field"
            />
          </Col>
          {!hidden && (
            <Col xs={12}>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                text="Download image"
                onClick={handleDownloadImage}
              />
            </Col>
          )}
          {!hidden && (
            <Col xs={12}>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                text="Download csv"
                onClick={handleDownloadCSV}
              />
            </Col>
          )}
        </Row>
      </TableContainer>
    </ChartSection>
  );
};

export default ChartComponent;
