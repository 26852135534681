
import React, { Component} from 'react';
import { SpinLoading } from '../../styles/BasicStyles';
import { GetSurvey, SubmitSurvey } from '../../infra/requests/SurveysRequests';
import { SurveyBackContainer, SurveyGroup } from './SurveysStyles';
import { BodySurvey, TitleSurveyH5 } from '../../styles/TextSizes';
import SurveyInfo from './SurveyInfo';
import SurveyIntro from './SurveyIntro';
import SurveyQuestions from './SurveyQuestions';
import { GetValuePreview } from '../../infra/utils/CommonFunctions';
import { formValueSelector, initialize, reduxForm } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import SurveySubmit from './SurveySubmit';
import { connect } from 'react-redux';

const validations = values => {
  const errors = FormValidator.make({
    chapters: 'array',
    client: 'required',
    assessment: 'required',
    role: 'required',
    entity: 'required',
  })(values);

  if (values.chapters && values.chapters.length > 0) {
    errors.chapters = [];
    values.chapters.map((chapter, index) => {
      errors.chapters[index] = FormValidator.make({
        present: 'required',
        future: 'required'
      })(chapter);

      errors.chapters[index].present = FormValidator.make({
        collaborate: 'required',
        create: 'required',
        compete: 'required',
        control: 'required'
      })(chapter);

      errors.chapters[index].future = FormValidator.make({
        collaborate: 'required',
        create: 'required',
        compete: 'required',
        control: 'required'
      })(chapter);

    });
  }
  return errors;
};

class SurveysPage extends Component {
  state = {
    section: 'overview',
    loading: true,
    survey: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      location
    } = this.props;

    this.setState({section: location?.hash ? location.hash.replace('#', '') : 'overview'})
    if (params.id && params.client) {
      const result = await GetSurvey(params.id, params.client)

      if (result.success) {
        this.setState({
          survey: {...result.data.assessment, client: result.data.client},
          loading: false
        });
        dispatch(initialize("survey_form", { chapters: [] }));
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      await SubmitSurvey(values);
      this.setState({ loading: false, section: 'success' });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  onPressNext = (next) => {
    this.setState({
      section: next
    });
  }

  renderContent = () => {
    const { activeLanguage, translate, handleSubmit, formValues, match: { params } } = this.props;
    const { section, survey } = this.state;
    switch (section) {
      case 'overview' :
        return <SurveyInfo survey={survey} onPressNext={this.onPressNext}/>
      case 'success':
        return (
          <SurveyBackContainer>
            <SurveyGroup>
              <TitleSurveyH5>
                {translate('THANK_YOU_FOR_YOUR_TIME')}
              </TitleSurveyH5>
              <BodySurvey>
                {GetValuePreview(survey.note, activeLanguage.code)}
              </BodySurvey>
            </SurveyGroup>
          </SurveyBackContainer>
        )
      case 'submit':
        return (
          <SurveySubmit survey={survey} onPressNext={this.onPressNext} section={section} handleSubmit={handleSubmit} assessment={params.id} client={params.client} />
        )
      case 'intro':
        return (
          <SurveyIntro survey={survey} onPressNext={this.onPressNext}/>
        )

      default:
        return (
          <SurveyQuestions key={section} survey={survey} onPressNext={this.onPressNext} section={section} formValues={formValues}/>
        )
    }
  }

  render() {
    const { translate } = this.props;
    const { loading, survey } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        {survey 
          ? this.renderContent() 
          : (
            <SurveyBackContainer>
              <SurveyGroup>
                <TitleSurveyH5>
                  {translate('OOPS')}
                </TitleSurveyH5>
                <BodySurvey>
                  {translate('SURVEY_NOT_FOUND')}
                </BodySurvey>
              </SurveyGroup>
            </SurveyBackContainer>
          )
        }
      </React.Fragment>
    );
  }
}

const selector = formValueSelector("survey_form");

SurveysPage = reduxForm({
  form: "survey_form",
  validate: validations,
})(SurveysPage);

const mapStateToProps = (state, ownProps) => ({
  formValues: selector(state, "chapters"),
});


export default withLocalize(connect(mapStateToProps)(SurveysPage));