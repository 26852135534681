import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Component that alerts if you click outside of it
 */
class OutsideAlerter extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", e => this.handleClickOutside(e, this.props.close));
  };

  handleClickOutside(event, callback) {
    if(
      this.wrapperRef.current && 
      !this.wrapperRef.current.contains(event.target) && 
      ((event.target.className &&
      typeof event.target.className.includes !== 'undefined' &&
      !(event.target.className && event.target.className.includes('langSelector')))
      ||
      !event.target.className)
    ) {
      callback();
    }
  };

  render() {
    return(
      <div ref={this.wrapperRef} 
        style={{  
            alignItems: "center",
            justifyContent: "flex-end",
            display: "inline-flex",
            width: "100%"
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
};

export default OutsideAlerter;
