import React from "react";
import { connect } from "react-redux";
import { 
  StyledHeader, 
  StyledLogoContainer,
  StyleLogoImg,
} from "./LayoutStyles";
import Logo from '../../../assets/icons/logo.svg';

const HeaderComponent = ({ user, logOut }) => {
  return (
    <StyledHeader className='no-print'>
      <StyledLogoContainer>
        <StyleLogoImg src={Logo}/>
      </StyledLogoContainer>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(HeaderComponent);