import styled from "styled-components";
import { ButtonColor, LightBackgroundColor, PrimaryBackgroundColor, PrimaryColour, SuccessButtonColor, SurveyInputPrimaryBackground, TextColor, TitleColor } from "../../styles/Colours";
import { device } from "../../styles/Responsive";

export const SurveyBackContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({bgColor}) => bgColor || LightBackgroundColor};
`;

export const SurveyBackContainer2 = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;

export const SurveyGroup = styled.div`
  max-width: 800px;
  position: relative;
  text-align: center;
`;

export const SurveyTitle = styled.h2`
  display: inline-block;
  width: 100%;
  font-size: 26px;
  line-height: 32px;
  margin: 0px;
  margin-bottom: 24px;
  color: ${(p) => p.theme.titleColor};
`;

export const SurveyDescription = styled.h5`
  display: inline-block;
  width: 100%;
  line-height: 22px;
  font-size: 16px;
  color: ${(p) => p.theme.textColor};
`;

export const SurveyScroll = styled.div`
  width: 100%;
  display: flex;
  background-color: ${LightBackgroundColor};
  overflow-y: auto;
  height: 100vh;
  -webkit-box-pack: center;
  justify-content: center;
`

export const SurveyContainer = styled.div`
  width: 100%;
  max-width: 749px;
  padding: 74px 0px 66px;
  text-align: center;
`

export const SurveyInfoImage = styled.img`
  max-width: 496px;
  max-height: 278px;
  margin-bottom: 40px;
  margin-top: 48px;
  width: 100%;
  height: auto;
  object-fit: contain;
`

const DefineColors = ({category, disabled}) => {
  switch (category) {
    case 'primary':
      return `
        background-color: ${ButtonColor};
        border: 1px solid ${ButtonColor};
        color: white;
      `
    case 'success':
      return `
        background-color: ${SuccessButtonColor};
        border: 1px solid ${SuccessButtonColor};
        color: white;
      `
    default:
      return `
        background-color: transparent;
        border: 1px solid ${TitleColor};
        color: ${TitleColor};
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 0px;
      `
  }
}

const DefineHover = ({category}) => {
  switch (category) {
    case 'primary':
      return `
        background-color: white;
        border: 1px solid ${ButtonColor};
        color: ${ButtonColor};

        svg path {
          fill: ${ButtonColor};
        }
      `
    case 'success':
      return `
        background-color: white;
        border: 1px solid ${SuccessButtonColor};
        color: ${SuccessButtonColor};

        svg path {
          fill: ${SuccessButtonColor};
        }
      `
    default:
      return `
        background-color: ${TitleColor};
        border: 1px solid ${TitleColor};
        color: #f4f4f4;

        svg path {
          fill: #f4f4f4;
        }
      `
  }
}

export const BaseStyledButton = styled.button`
  display: inline-block;
  height: 50px;
  padding: 0px 23px;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  ${DefineColors};

  &:not(.noHover):hover,
  &:not(.noHover):visited,
  &:not(.noHover):active {
    outline: none;
    ${DefineHover}
  }

  &:disabled {
    opacity: 0.5;
    ${DefineColors}
  }

  &.small {
    padding: 0px 10px;
    height: 30px;
  }
`

export const SurveyHeader = styled.div`
  width: 100%;
  padding: 32px 64px 24px 166px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${PrimaryColour};
  height: 112px;

  @media ${device.desktop} {
    padding: 32px 32px 24px 32px;
  }
`;

export const SurveyHeader2 = styled.div`
  width: 100%;
  padding: 32px 64px 24px 166px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${PrimaryColour};
  height: 174px;

  @media ${device.desktop} {
    padding: 32px 32px 24px 32px;
  }
`;

export const SurveyHeaderRestart = styled.button`
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  outline: none;
  border: none;
  border-radius: 50%;
  float: right;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    outline: none;
    background-color: ${LightBackgroundColor};
  }
`;

export const SurveyFooter = styled.div`
  width: 100%;
  padding: 0px 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f4;
  height: 90px;

  .ant-progress-status-success .ant-progress-text {
    color: ${SuccessButtonColor};
  }

  @media ${device.desktop} {
    padding: 0px 32px;
  }
`;

export const SurveyFooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SurveyScroll2 = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  height: calc(100vh - 90px - 112px);
  -webkit-box-pack: center;
  justify-content: center;
`

export const SurveyScroll3 = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  height: calc(100vh - 90px - 174px);
  -webkit-box-pack: center;
  justify-content: center;
`

export const SurveyBox = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 166px; 
  -webkit-box-pack: center;
  justify-content: center;
  margin: auto;

  @media ${device.desktop} {
    padding: 40px 32px;
  }
`

export const SurveyInfoImagePlaceholder = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-color: ${LightBackgroundColor};
  min-height: 300px;
`

export const SurveyImage = styled.img`
  width: 100%;
`

/********************************************************* SURVEY CHAPTER QUESTIONS *********************************************************/

export const SurveyChapterTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  letter-spacing: normal;
  color: ${TextColor};
`

export const StyledSurveyQuestionHeader = styled.th`
  background-color: ${({$empty, $completed , $isPrimary}) => $empty ? 
    'transparent' : 
    $completed ? 
      SuccessButtonColor : 
      $isPrimary ? 
        SurveyInputPrimaryBackground : 
        LightBackgroundColor};
  padding: 16px;
  border-top-left-radius: ${({$isLeftHeader}) => $isLeftHeader ? "16px" : "0px"};
  border-top-right-radius: ${({$isRightHeader}) => $isRightHeader ? "16px" : "0px"};
  border-bottom-left-radius: ${({$isLeftFooter}) => $isLeftFooter ? "16px" : "0px"};
  border-bottom-right-radius: ${({$isRightFooter}) => $isRightFooter ? "16px" : "0px"};
  border-left: 2px solid ${PrimaryBackgroundColor};
  border-right: 2px solid ${PrimaryBackgroundColor};
  &:first-child{
   width: 50%;
  }

  &:last-child{
    ${({$isPrimary}) => $isPrimary ? `background-color: ${LightBackgroundColor}` : undefined}
  }
`

export const StyledSurveyQuestionHeaderTitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  letter-spacing: normal;
  color: ${({color}) => color || TextColor};
`

export const StyledSurveyQuestion = styled.td`
  background-color: ${({$empty, $completed , $isPrimary}) => $empty ? 
    'transparent' : 
    $completed ? 
      SuccessButtonColor : 
      $isPrimary ? 
        SurveyInputPrimaryBackground : 
        LightBackgroundColor};
  padding: 16px;
  border-top-left-radius: ${({$isLeftHeader}) => $isLeftHeader ? "16px" : "0px"};
  border-top-right-radius: ${({$isRightHeader}) => $isRightHeader ? "16px" : "0px"};
  border-bottom-left-radius: ${({$isLeftFooter}) => $isLeftFooter ? "16px" : "0px"};
  border-bottom-right-radius: ${({$isRightFooter}) => $isRightFooter ? "16px" : "0px"};
  border-left: 2px solid ${PrimaryBackgroundColor};
  border-right: 2px solid ${PrimaryBackgroundColor};
  min-width: 300px;

  &:first-child{
   width: 50%;
  }

  &:last-child{
    ${({$isPrimary}) => $isPrimary ? `background-color: ${LightBackgroundColor}` : undefined}
  }

  ${StyledSurveyQuestionHeaderTitle}{
    color: ${({$completed}) => $completed ? PrimaryBackgroundColor : TitleColor};
  }
`

export const StyledSurveyQuestionTR = styled.tr`
  text-align: center;
  width: 100%;

  &:nth-child(even) {
    ${StyledSurveyQuestion}{
      background-color: #fbfbfb;
    }
  }
`
export const StyledSurveyQuestionHeaderSubTitle = styled.div`
  width: 100%;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.17;
  text-align: center;
  color: ${TextColor};
`

export const StyledSurveyTable = styled.table`
  width: 100%;
`

export const StyledSurveyText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${TextColor};
  text-align: left;
`

export const SurveyBox2 = styled.div`
  width: 100%;
  padding: 40px 166px;

  @media ${device.desktop} {
    padding: 40px 32px;
  }
`