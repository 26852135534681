import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initialize, Field, reduxForm } from "redux-form";
import ManageFormHeader from "../../components/base/ManageFormHeader";
import {
  CreateClient,
  EditClient,
  GetClient,
} from "../../infra/requests/ClientRequests";
import FormValidator from "../../infra/services/validations/FormValidator";
import { BaseForm, FormContainer, SpinLoading } from "../../styles/BasicStyles";
import { CloseMenu } from "../../redux/Menu/menu.actions";
import { FlattenToFormData } from "../../infra/services/formdata/TransformToFormData";
import { Col, Row } from "antd";
import TextInput from "../../components/generic/inputs/TextInput";
import { GetCountries } from "../../infra/requests/BaseRequests";
import SelectInput from "../../components/generic/inputs/SelectInput";
import ImageInput from "../../components/generic/inputs/ImageInput";

const validations = FormValidator.make({
  name: "required",
  country: "required",
  address: "required",
  city: "required",
  postal_code: "required",
});

class ManageClientPage extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      dispatch,
    } = this.props;

    CloseMenu();

    const countries = await GetCountries();

    if (params.id) {
      const result = await GetClient(params.id);
      dispatch(initialize("manage_client_form", { ...result.data }));

      this.setState({
        ready: true,
        countries: countries.data,
        loading: false,
        isNew: false,
        info: result || {},
      });
    } else {
      this.setState({
        loading: false,
        ready: true,
        isNew: true,
        countries: countries.data,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      const { isNew } = this.state;

      if (values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData({ ...values });
      isNew
        ? await CreateClient(payload)
        : await EditClient(params.id, payload);
      this.setState({ loading: false });
      return history.push("/clients");
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push("/clients");
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, ready, countries, loading } = this.state;

    if (!ready) return <SpinLoading />;
    const title = isNew ? "Add" : "Edit";

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={["Clients", title]}
          buttons={[
            {
              type: "primary",
              icon: "save",
              text: "Save",
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
              loading,
            },
            {
              type: "default",
              icon: "close",
              text: "Cancel",
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name="name"
                  type="text"
                  label="Name *"
                  placeholder={"Insert the  name of client"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name="address"
                  type="text"
                  label="Address *"
                  placeholder={"Insert the address of client"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name="city"
                  type="text"
                  label="City *"
                  placeholder={"Insert the city of client"}
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name="postal_code"
                  type="text"
                  label="Postal Code *"
                  placeholder="Insert the Postal Code of client"
                />
              </Col>
              <Col xs={24}>
                <Field
                  component={SelectInput}
                  name="country"
                  type="text"
                  label="Country *"
                  placeholder="Select the country of client"
                  data={countries}
                  dataKey={"_id"}
                  dataLabel={"name"}
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field component={ImageInput} name="logo" label={"Logo"} />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageClientPage = reduxForm({
  form: "manage_client_form",
  validate: validations,
})(ManageClientPage);

const mapActionToProps = (dispatch) =>
  bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageClientPage));
