import React, { Component } from "react";
import Table from "../../components/generic/table/Table";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  StyledBaseHeaderButtonsContainer,
  StyledBaseHeaderButtons,
} from "../../styles/BasicStyles";
import { ToggleClient, GetClients } from "../../infra/requests/ClientRequests";
import ClientFilters from "./ClientFilters";
import BaseButton from "../../components/generic/buttons/BaseButton";
import ClientsGridColumns from "./ClientsGridColumns";
import { GetCulturalAssessmentsDropdown } from "../../infra/requests/CulturalAssessmentsRequests";
import { Row, Col, Icon, notification } from "antd";
import { LinkSection } from "./ClientStyles";
import CalculateClientAssessments from "./CalculateClientAssessments";
import { CopyToClipboard } from "react-copy-to-clipboard";

class ClientsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: "",
    loading: true,
    ready: false,
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.getAssessments();
    await this.updateDataTable();
  };

  getAssessments = async () => {
    const assessments = await GetCulturalAssessmentsDropdown();
    const list = assessments?.data || [];
    let selected;
    if (list.length) selected = list[0]._id;
    this.setState({ selected });
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetClients(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/clients/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  deactivateItem = async (data) => {
    try {
      this.setState({ loading: true });
      await ToggleClient(data._id, false);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  activateItem = async (data) => {
    try {
      this.setState({ loading: true });
      await ToggleClient(data._id, true);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  handleExpendedRow = (data) => {
    const { selected } = this.state;
    const link = `${process.env.REACT_APP_BO_URL}surveys/${selected}/${data.code}`;
    return (
      <Row style={{ cursor: "default" }}>
        <Col xs={24}>
          <LinkSection>
            Survey Link:{" "}
            <a href={link} rel="noopener noreferrer" target="_blank">
              {link}
            </a>
            {"  "}
            <CopyToClipboard
              text={link}
              onCopy={() =>
                notification.success({
                  message: "Copied to clipboard",
                  duration: 2,
                })
              }
            >
              <span>
                <Icon style={{ cursor: "pointer" }} type="copy" />
              </span>
            </CopyToClipboard>
          </LinkSection>
        </Col>
        <Col xs={24}>
          <CalculateClientAssessments assessment={selected} client={data._id} />
        </Col>
      </Row>
    );
  };

  render() {
    const { history } = this.props;
    const { rows, total, loading, currentPage, pageSize } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Clients</PageTitle>
          </HeaderTitle>
          <StyledBaseHeaderButtonsContainer buttons={1}>
            <StyledBaseHeaderButtons>
              <BaseButton
                type={"primary"}
                icon={"plus"}
                text="Add Client"
                onClick={() => history.push("/clients/add")}
              />
            </StyledBaseHeaderButtons>
          </StyledBaseHeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ClientFilters queryChange={this.handleFilterChange} />
          <Table
            columns={ClientsGridColumns(this.deactivateItem, this.activateItem)}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader
            emptyIcon="warning"
            emptyText={"There are no clients"}
            total={total}
            rowKey={"_id"}
            hasPagination
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={this.handleExpendedRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ClientsPage;
