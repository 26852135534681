import styled from "styled-components";
import { SecondaryColour } from "../../styles/Colours";

export const LinkSection = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  a {
    color: ${SecondaryColour};

    &:hover {
      text-decoration: underline;
    }
  }
`;
