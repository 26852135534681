import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'

import { BaseStyledButton, SurveyBackContainer2, SurveyBox2, SurveyFooter, SurveyFooterRight, SurveyHeader2, SurveyHeaderRestart, SurveyScroll3 } from './SurveysStyles'
import { BodySmallSurvey, TextHeader2Survey, TitleSurveyH5 } from '../../styles/TextSizes'
import {ReactComponent as Shape} from '../../assets/icons/chevron-right-blue.svg'
import {ReactComponent as ShapeBack} from '../../assets/icons/chevron-left-blue.svg'
import {ReactComponent as Refresh} from '../../assets/icons/refresh.svg'
import { Col, Row, Progress } from 'antd'
import { GetValuePreview } from '../../infra/utils/CommonFunctions'
import { SuccessButtonColor } from '../../styles/Colours'
import { reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import SurveyChapterComponent from './SurveyChapterComponent'
import Language from '../../components/generic/language/Language'
import { RowContainer } from '../../styles/BasicStyles'

const SurveyQuestions = ({
  survey,
  section,
  onPressNext,
  activeLanguage,
  translate,
  formValues
}) => {
  const chapter = survey.chapters[section - 1]
  const percent = section === 1 ? 0 : parseInt(((section - 1) / survey.chapters.length) * 100, 10)
  const dispatch = useDispatch();

  const onPressStart = () => {
    dispatch(reset('survey_form'))
    onPressNext('intro')
  }

  const presentTotalValue = (formValues[section - 1]?.present?.collaborate || 0) + (formValues[section - 1]?.present?.create || 0) + (formValues[section - 1]?.present?.compete || 0) + (formValues[section - 1]?.present?.control || 0) 
  const futureTotalValue = (formValues[section - 1]?.future?.collaborate || 0) + (formValues[section - 1]?.future?.create || 0) + (formValues[section - 1]?.future?.compete || 0) + (formValues[section - 1]?.future?.control || 0)

  return (
    <SurveyBackContainer2>
      <SurveyHeader2>
        <Row style={{ width: '100%'}} justify={'end'}>
        <Col lg={18} xs={24}>
          <TextHeader2Survey style={{marginBottom: 8}}>{translate("CATEGORY_NUMBER", {number: section})}</TextHeader2Survey>
          <TitleSurveyH5 color='#ffffff' style={{marginBottom: 16}}>{GetValuePreview(chapter.title, activeLanguage.code)}</TitleSurveyH5>
          <BodySmallSurvey color='#ffffff'>{GetValuePreview(chapter.description, activeLanguage.code)}</BodySmallSurvey>
        </Col>    
        <Col lg={6} xs={24}>
          <RowContainer>
          <Language paddingRight={30} />
          <SurveyHeaderRestart onClick={onPressStart}>
            <Refresh />
          </SurveyHeaderRestart>
          </RowContainer>
          
        </Col>      
       </Row>
      </SurveyHeader2>
      <SurveyScroll3>
        <SurveyBox2>
          <SurveyChapterComponent 
            index={section - 1} 
            chapter={chapter} 
            presentTotalValue={presentTotalValue} 
            futureTotalValue={futureTotalValue}
          /> 
        </SurveyBox2>
      </SurveyScroll3>
      <SurveyFooter>
        <Progress 
          type="circle" 
          percent={percent} 
          width={56}
          trailColor='#c0c0c0'
          strokeColor={percent === 100 ? SuccessButtonColor : '#0b4081'}
          success={{ percent: 100 }}
          status={percent === 100 ? 'success' : 'normal'}
          format={percent => `${percent}%`}
        />
        <SurveyFooterRight>
          {section > 1 && (
            <BaseStyledButton
              style={{display: 'flex', marginRight: 8}}
              category='secondary'
              onClick={() => onPressNext(section - 1)}
            >
              <ShapeBack />
            </BaseStyledButton>
          )}
          <BaseStyledButton
            style={{display: 'flex'}}
            category='secondary'
            onClick={() => section === survey.chapters.length ? onPressNext('submit') : onPressNext(section + 1)}
            disabled={presentTotalValue !== 100 || futureTotalValue !== 100}
          >
            <Shape />
          </BaseStyledButton>
        </SurveyFooterRight>
      </SurveyFooter>
    </SurveyBackContainer2>
  )
}

SurveyQuestions.propTypes = {
  survey: PropTypes.object,
  section: PropTypes.number.isRequired,
  onPressNext: PropTypes.func,
  activeLanguage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

SurveyQuestions.defaultProps = {
  onPressNext: () => {},
}

export default withLocalize(SurveyQuestions)
