export const getImage = (file_id) => {
  return `${process.env.REACT_APP_IMAGES_URL}${file_id}`;
};

export const GetValuePreview = (value, lang) => {
  if (!value) return null;
  if (typeof value === "string") return value;
  if (value && value[lang] && value[lang] !== "") return value[lang];
  if (value && value.en && value.en !== "") return value.en;
  return value[Object.keys(value)[0]];
};

export const TranslateOrEmpty = (value, code, tag) => {
  if (value) {
    if (typeof value === "string") return value;
    if (typeof value === "object") {
      if (value[code] && value[code] !== "") return value[code];
      if (value["en"] && value["en"] !== "") return value["en"];
      const keys = Object.keys(value);
      if (keys && keys.length) return value[keys[0]];
    }
  }
  return tag;
};
