import React from "react";
import { Popconfirm } from "antd";
import {
  StyledBaseAction,
  StyledBaseDeleteIcon,
} from "../../styles/BasicStyles";
import { ErrorColor, SuccessColor } from "../../styles/Colours";

const ClientsGridColumns = (handleDeactivateRow, handleActivateRow) => {
  const options = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      render: (value) => (
        <div onClick={(e) => e.stopPropagation()}>{value}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) =>
        !active ? (
          <span style={{ color: ErrorColor }}>Inactive</span>
        ) : (
          <span style={{ color: SuccessColor }}>Active</span>
        ),
    },
    {
      title: "Actions",
      render: (data) =>
        !data.active ? (
          <StyledBaseAction onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement="topRight"
              title="Do you want to activate this client?"
              onConfirm={() => handleActivateRow(data)}
            >
              <StyledBaseDeleteIcon type={"rollback"} /> Activate
            </Popconfirm>
          </StyledBaseAction>
        ) : (
          <StyledBaseAction onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement="topRight"
              title="Do you want to deactivate this group?"
              onConfirm={() => handleDeactivateRow(data)}
            >
              <StyledBaseDeleteIcon type={"stop"} /> Deactivate
            </Popconfirm>
          </StyledBaseAction>
        ),
    },
  ];

  return options;
};

export default ClientsGridColumns;
