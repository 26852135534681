import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledSurveySlider, StyledSurveySliderBox } from './InputStyles';
import { BodySmall2Survey } from '../../../styles/TextSizes';


const SurveyQuestionSlider = ({
  input,
  maxValue
}) => {
  const [value, setValue] = useState(input.value || 0)
  return (
    <StyledSurveySliderBox>
      <BodySmall2Survey style={{position: 'absolute', top: -20, left: value > 50 ? `${value - 5}%` : `${value}%`}}>{value}</BodySmall2Survey>
      <StyledSurveySlider
        step={5} 
        max={100}
        value={value}
        defaultValue={input.value || 0}
        onAfterChange={(value) => {
          if (maxValue < 0) {
            input.onChange(value + maxValue)
            setValue(value + maxValue)
          }
        }}
        onChange={(value) => {
          input.onChange(value)
          setValue(value)
        }}
        tooltipVisible={false}
      />
    </StyledSurveySliderBox>
  );
};

SurveyQuestionSlider.propTypes = {
  input: PropTypes.object.isRequired,
};

SurveyQuestionSlider.defaultProps = {
    maxValue: 100,
  };

export default SurveyQuestionSlider;