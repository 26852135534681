import React from 'react'
import { Col, Row } from "antd";
import { 
    SurveyChapterTitle,
    StyledSurveyQuestionHeader,
    StyledSurveyQuestion,
    StyledSurveyQuestionHeaderTitle,
    StyledSurveyQuestionHeaderSubTitle,
    StyledSurveyQuestionTR,
    StyledSurveyTable,
    StyledSurveyText, 
  } from './SurveysStyles';
import { withLocalize } from 'react-localize-redux';
import { Field } from 'redux-form';
import SurveyQuestionSlider from '../../components/generic/inputs/SurveyQuestionSlider';
import { StyledBaseContainer } from '../../styles/BasicStyles';
import { GetValuePreview } from '../../infra/utils/CommonFunctions';
import { PrimaryColour } from '../../styles/Colours';

const SurveyChapterComponent = ({translate, activeLanguage, presentTotalValue, futureTotalValue, index, chapter}) => {
    return (
      <>
      <Row>
        <Col md={12}>
          <SurveyChapterTitle>
            {GetValuePreview(chapter.question, activeLanguage.code)}
          </SurveyChapterTitle>
        </Col>
      </Row>
      <Row>
        <StyledSurveyTable>
          <thead>
            <StyledSurveyQuestionTR>
              <StyledSurveyQuestionHeader $empty />
              <StyledSurveyQuestionHeader $isPrimary $isLeftHeader>
                <StyledBaseContainer>
                  <StyledSurveyQuestionHeaderTitle color={PrimaryColour}>
                    {translate("NOW")}
                  </StyledSurveyQuestionHeaderTitle>
                  <StyledSurveyQuestionHeaderSubTitle>
                    {translate("AS_IT_IS_TODAY")}
                  </StyledSurveyQuestionHeaderSubTitle>
                </StyledBaseContainer>
              </StyledSurveyQuestionHeader>
              <StyledSurveyQuestionHeader $isRightHeader>
              <StyledBaseContainer>
                  <StyledSurveyQuestionHeaderTitle color={PrimaryColour}>
                    {translate("FUTURE")}
                  </StyledSurveyQuestionHeaderTitle>
                  <StyledSurveyQuestionHeaderSubTitle>
                    {translate("TO_BE_SUCCESSFUL")}
                  </StyledSurveyQuestionHeaderSubTitle>
                </StyledBaseContainer>
              </StyledSurveyQuestionHeader> 
            </StyledSurveyQuestionTR>
          </thead> 
          <tbody>
            <StyledSurveyQuestionTR>
              <StyledSurveyQuestion $isPrimary>
                <StyledSurveyText>
                  {GetValuePreview(chapter.collaborate, activeLanguage.code)}
                </StyledSurveyText>
              </StyledSurveyQuestion>
              <StyledSurveyQuestion $isPrimary>
                <Field
                  component={SurveyQuestionSlider}
                  name={`chapters[${index}].present.collaborate`}
                  maxValue={100 - presentTotalValue}
                />
              </StyledSurveyQuestion>
              <StyledSurveyQuestion $isPrimary>
              <Field
                  component={SurveyQuestionSlider}
                  name={`chapters[${index}].future.collaborate`}
                  maxValue={100 - futureTotalValue}
                />
              </StyledSurveyQuestion>
            </StyledSurveyQuestionTR>
            <StyledSurveyQuestionTR $secondary>
              <StyledSurveyQuestion>
              <StyledSurveyText>
              {GetValuePreview(chapter.create, activeLanguage.code)}
                </StyledSurveyText>
              </StyledSurveyQuestion>
              <StyledSurveyQuestion>             
              <Field
                  component={SurveyQuestionSlider}
                  name={`chapters[${index}].present.create`}
                  maxValue={100 - presentTotalValue}
                />
              </StyledSurveyQuestion>
              <StyledSurveyQuestion>             
                <Field
                    component={SurveyQuestionSlider}
                    name={`chapters[${index}].future.create`}
                    maxValue={100 - futureTotalValue}
                />
              </StyledSurveyQuestion>
            </StyledSurveyQuestionTR>
            <StyledSurveyQuestionTR>
              <StyledSurveyQuestion $isPrimary>
              <StyledSurveyText>
                {GetValuePreview(chapter.compete, activeLanguage.code)}
              </StyledSurveyText>
              </StyledSurveyQuestion>
              <StyledSurveyQuestion $isPrimary>
              <Field
                    component={SurveyQuestionSlider}
                    name={`chapters[${index}].present.compete`}
                    maxValue={100 - presentTotalValue}
                />

              </StyledSurveyQuestion>
              <StyledSurveyQuestion $isPrimary>
              <Field
                    component={SurveyQuestionSlider}
                    name={`chapters[${index}].future.compete`}
                    maxValue={100 - futureTotalValue}
                />
                
              </StyledSurveyQuestion>
            </StyledSurveyQuestionTR>
            <StyledSurveyQuestionTR $secondary>
              <StyledSurveyQuestion>
              <StyledSurveyText>
                {GetValuePreview(chapter.control, activeLanguage.code)}
              </StyledSurveyText> 
              </StyledSurveyQuestion>
              <StyledSurveyQuestion>
              <Field
                    component={SurveyQuestionSlider}
                    name={`chapters[${index}].present.control`}
                    maxValue={100 - presentTotalValue}
                />              
              </StyledSurveyQuestion>
              <StyledSurveyQuestion>
              <Field
                    component={SurveyQuestionSlider}
                    name={`chapters[${index}].future.control`}
                    maxValue={100 - futureTotalValue}
                />              
                </StyledSurveyQuestion>
            </StyledSurveyQuestionTR>
          </tbody>
          <tfoot> 
            <StyledSurveyQuestionTR>
              <StyledSurveyQuestion $empty/>
              <StyledSurveyQuestion $isLeftFooter $completed={presentTotalValue === 100}>
                <StyledSurveyQuestionHeaderTitle>
                  {presentTotalValue}/100
                </StyledSurveyQuestionHeaderTitle>
              </StyledSurveyQuestion>
              <StyledSurveyQuestion $isRightFooter $completed={futureTotalValue === 100}>
              <StyledSurveyQuestionHeaderTitle>
                {futureTotalValue}/100
              </StyledSurveyQuestionHeaderTitle>
              </StyledSurveyQuestion>
            </StyledSurveyQuestionTR>
          </tfoot>
        </StyledSurveyTable>
        </Row>
      </>
    )
  }
  
  export default withLocalize(SurveyChapterComponent)