import React, { createRef, useState } from "react";
import { Margin, SpinLoading } from "../../styles/BasicStyles";
import {
  EmptyState,
  GraphicTitle,
  Preview,
  PreviewDescription,
  PreviewHeader,
  PreviewSubtitle,
  PreviewTitle,
} from "./DashboardStyles";
import { Row, Col, Icon } from "antd";
import ChartComponent from "./ChartComponent";
import { TranslateOrEmpty } from "../../infra/utils/CommonFunctions";
import BaseButton from "../../components/generic/buttons/BaseButton";
import { useScreenshot, createFileName } from "use-react-screenshot";
import LegalEntities from "../../infra/utils/LegalEntities";

const CategorySections = ({
  data,
  loading,
  client,
  role,
  entity,
  assessment,
}) => {
  const [printing, setPrinting] = useState(false);
  const [hidden, setHidden] = useState(false);
  const ref = createRef(null);
  const chapters = assessment?.chapters || [];

  const [, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image, { name = "Report", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
    setPrinting(false);
    setHidden(false);
  };

  const downloadScreenshot = () => {
    setPrinting(true);
    takeScreenshot(ref.current).then(download);
  };

  if (!client)
    return (
      <EmptyState>
        <Icon type="radar-chart" />
        <div>Select a client first</div>
      </EmptyState>
    );

  if (loading) return <SpinLoading />;

  if (data.length === 0)
    return (
      <EmptyState>
        <Icon type="radar-chart" />
        <div>There are no assessments yet</div>
      </EmptyState>
    );

  const entities = entity.length
    ? entity.map((e) => {
        const found = LegalEntities.find((l) => l._id == e);
        return found?.name || "";
      })
    : ["All"];

  return (
    <Row gutter={24}>
      <Col xs={24}>
        <Margin $size="20" />
        {hidden && (
          <BaseButton
            type="primary"
            text="Download report"
            onClick={downloadScreenshot}
            loading={printing}
            disabled={printing}
            style={{ float: "right", marginLeft: "30px" }}
          />
        )}
        {!hidden && (
          <BaseButton
            type="primary"
            text="Preview report"
            onClick={() => setHidden(true)}
            style={{ float: "right" }}
          />
        )}
        {hidden && (
          <BaseButton
            type="default"
            text="Cancel"
            onClick={() => setHidden(false)}
            style={{ float: "right" }}
          />
        )}
      </Col>
      <Col xs={24}>
        <Preview ref={ref}>
          {hidden && (
            <PreviewHeader>
              <PreviewTitle>
                {TranslateOrEmpty(assessment.name, "en")}
              </PreviewTitle>
              <PreviewSubtitle>
                <span>Client:</span> {client?.name || ""}
              </PreviewSubtitle>
              <PreviewDescription>
                <span>Workpalces:</span>{" "}
                {entities.map(
                  (e, i) => `${e}${i < entities.length - 1 ? "; " : ""}`
                )}
              </PreviewDescription>
              <PreviewDescription>
                <span>Role:</span> {role?.name || "All"}
              </PreviewDescription>
            </PreviewHeader>
          )}
          <Row>
            <Col xs={24}>
              <GraphicTitle>All Categories</GraphicTitle>
              <ChartComponent
                data={data}
                loading={loading}
                client={client}
                role={role}
                entity={entity}
                hidden={hidden}
              />
            </Col>
            {chapters.map((chapter, cIndex) => (
              <Col xs={24} key={cIndex}>
                <GraphicTitle>
                  {cIndex + 1}º Category:{" "}
                  {TranslateOrEmpty(chapter.title, "en")}
                </GraphicTitle>
                <ChartComponent
                  data={data}
                  loading={loading}
                  client={client}
                  role={role}
                  entity={entity}
                  category={cIndex}
                  hidden={hidden}
                />
              </Col>
            ))}
          </Row>
        </Preview>
      </Col>
    </Row>
  );
};

export default CategorySections;
