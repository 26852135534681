import DashboardPage from "../../pages/dashboard/DashboardPage";
import AdminsPage from "../../pages/admins/AdminsPage";
import ManageAdminPage from "../../pages/admins/ManageAdminPage";
import ClientsPage from "../../pages/clients/ClientsPage";
import TranslationsPage from "../../pages/translations/TranslationsPage";
import DynamicPages from "../../pages/dynamicPages/DynamicPages";
import ManageDynamicPages from "../../pages/dynamicPages/ManageDynamicPages";
import EmailTemplatesPage from "../../pages/email_templates/EmailTemplatesPage";
import ManageEmailTemplatesPage from "../../pages/email_templates/ManageEmailTemplatesPage";
import ManageClientPage from "../../pages/clients/ManageClientPage";
import CulturalAssessmentsPage from "../../pages/cultural_assessments/CulturalAssessmentsPage";
import ManageCulturalAssessments from "../../pages/cultural_assessments/ManageCulturalAssessments";
import SurveysPage from "../../pages/surveys/SurveysPage";

export default [
  {
    path: "/",
    component: DashboardPage,
    isAuthenticated: true,
  },
  {
    path: "/administrators",
    component: AdminsPage,
    isAuthenticated: true,
  },
  {
    path: "/administrators/add",
    component: ManageAdminPage,
    isAuthenticated: true,
  },
  {
    path: "/administrators/:id",
    component: ManageAdminPage,
    isAuthenticated: true,
  },
  {
    path: "/clients",
    component: ClientsPage,
    isAuthenticated: true,
  },
  {
    path: "/clients/add",
    component: ManageClientPage,
    isAuthenticated: true,
  },
  {
    path: "/clients/:id",
    component: ManageClientPage,
    isAuthenticated: true,
  },
  {
    path: "/cultural-assessments",
    component: CulturalAssessmentsPage,
    isAuthenticated: true,
  },
  {
    path: "/cultural-assessments/:id",
    component: ManageCulturalAssessments,
    isAuthenticated: true,
  },
  {
    path: "/translations",
    component: TranslationsPage,
    isAuthenticated: true,
  },
  {
    path: "/dynamic-pages",
    component: DynamicPages,
    isAuthenticated: true,
  },
  {
    path: "/dynamic-pages/:id",
    component: ManageDynamicPages,
    isAuthenticated: true,
  },
  {
    path: "/email-templates",
    component: EmailTemplatesPage,
    isAuthenticated: true,
  },
  {
    path: "/email-templates/:id",
    component: ManageEmailTemplatesPage,
    isAuthenticated: true
  },
  {
    path: '/surveys/:id/:client',
    component: SurveysPage,
    isAuthenticated: false
  }
];
